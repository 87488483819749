class Accordions extends HTMLElement {
  constructor() {
    super()

    const ACCORDION_ACTIVE_CLASS = 'active'
    const ACCORDION_CONTENT_OPEN_CLASS = 'is-open'
    const ACCORDION_FOCUS_CLASS = 'is-focus'
    const ACCORDION_CONTENT_SELECTOR = '.js-content'
    const ACCORDION_BUTTON_SELECTOR = '.js-btn'
    const ACCORDION_ITEM_SELECTOR = '.js-item'
    const OPEN_FIRST_ITEM_SELECTOR = '.js-open-first'

    const closeAll = (list) => {
      list.forEach(item => {
        close(item)
      })
    }

    const close = (item) => {
      item.classList.remove(ACCORDION_ACTIVE_CLASS)
      const content = item.querySelector(ACCORDION_CONTENT_SELECTOR)
      const btn = item.querySelector(ACCORDION_BUTTON_SELECTOR)
      if (btn && content) {
        btn.classList.remove(ACCORDION_FOCUS_CLASS)
        content.style.maxHeight = '0px'
        content.classList.remove(ACCORDION_CONTENT_OPEN_CLASS)
        btn.setAttribute('aria-expanded', 'false')
      }
    }

    const open = (accordion, content, item) => {
      accordion.classList.add(ACCORDION_ACTIVE_CLASS)
      setHeight(content)
      content.classList.add(ACCORDION_CONTENT_OPEN_CLASS)
      item.setAttribute('aria-expanded', 'true')
    }

    const toggleDescription = (item, list) => {
      const accordion = item.parentNode
      const content = accordion.querySelector(ACCORDION_CONTENT_SELECTOR)

      if (accordion && !accordion.classList.contains(ACCORDION_ACTIVE_CLASS)) {
        open(accordion, content, item)
      } else {
        close(accordion)
      }
    }

    const setHeight = (item) => {
      item.style.maxHeight = item.children[0].scrollHeight + 'px'
    }

    const list = this.querySelectorAll(ACCORDION_ITEM_SELECTOR)
    const onlyOneItemOpened = this.dataset.onlyOneItemOpened
    const triggerName = this.dataset.triggerName
    const openFirstItemEl = this.querySelector(OPEN_FIRST_ITEM_SELECTOR)
    if (list.length) {
      list.forEach(item => {
        const button = item.querySelector(ACCORDION_BUTTON_SELECTOR)
        if (button) {
          button.onclick = function () {
            if (onlyOneItemOpened) {
              if (item && item.classList.contains(ACCORDION_ACTIVE_CLASS)) {
                close(item)
              } else {
                closeAll(list)
                const content = item.querySelector(ACCORDION_CONTENT_SELECTOR)
                open(item, content, button)
              }
            } else {
              toggleDescription(button, list)
            }

            if (triggerName) {
              this.dispatchEvent(new CustomEvent(triggerName, {
                detail: {
                  itemId: item.dataset.itemId || ''
                },
                bubbles: true
              }))
            }
          }
        }
      })

      if (openFirstItemEl) {
        toggleDescription(openFirstItemEl, list)
      }
    }

    window.addEventListener('resize', () => {
      if (list.length) {
        list.forEach(item => {
          const button = item.querySelector(ACCORDION_BUTTON_SELECTOR)
          const accordion = button.parentNode
          if (accordion && accordion.classList.contains(ACCORDION_ACTIVE_CLASS)) {
            const content = accordion.querySelector(ACCORDION_CONTENT_SELECTOR)
            setHeight(content)
          }
        })
      }
    })
  }
}

customElements.define('accordion-list', Accordions)
